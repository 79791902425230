module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Bullit Energy Drink | The Official Website', // Navigation and Site Title
  titleAlt: 'Bullit Energy Drink | The Official Website', // Title for JSONLD
  description:
    'Bullit Energy Drink is een echt Hollands merk en is sinds 1995 verkrijgbaar in Nederland. Bullit Energy Drink is uitgegroeid tot 1 van de grootste energie dranken in ons land. Vooral onze focus op de supermarkt heeft hieraan bijgedragen.',
  headline: 'Bullit Energy Drink | The Official Website', // Headline for schema.org JSONLD
  url: 'https://www.bullitenergydrink.com', // Domain of your site. No trailing slash!

  // JSONLD / Manifest
  favicon: 'src/favicon.png',
  shortName: 'Bullit',
  author: 'Bullit',
  themeColor: '#25639E',
  backgroundColor: '#FFFFFF',
  googleAnalyticsID: 'UA-156276234-1'
}
