const website = require('./website')

module.exports = {
  // 'en-us': {
  //   path: 'en-en',
  //   locale: 'en-US',
  //   siteLanguage: 'en',
  //   ogLang: 'en_US',
  //   defaultTitle: website.title,
  //   defaultTitleAlt: website.titleAlt,
  //   defaultDescription: website.description
  // },
  'nl-nl': {
    default: true,
    path: 'nl-nl',
    locale: 'nl-nl',
    siteLanguage: 'nl',
    ogLang: 'nl_NL',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
  },
  'fr-fr': {
    path: 'fr-fr',
    locale: 'fr-fr',
    siteLanguage: 'fr',
    ogLang: 'fr_FR',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
  },
}
