module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography.js"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bullit Energy Drink | The Official Website","short_name":"Bullit Energy Drink | The Official Website","description":"Bullit Energy Drink is een echt Hollands merk en is sinds 1995 verkrijgbaar in Nederland. Bullit Energy Drink is uitgegroeid tot 1 van de grootste energie dranken in ons land. Vooral onze focus op de supermarkt heeft hieraan bijgedragen.","start_url":"","background_color":"#FFFFFF","theme_color":"#25639E","display":"standalone","icon":"src/favicon.png"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
