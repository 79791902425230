import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../SEO";
import Footer from "../Footer";

import i18n from "../../../config/i18n";

const GlobalContext = React.createContext();

// Smooth Scrolling
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    header: "[data-scroll-header]",
    offset: 30
  });
}

// Query Global Layout content (Nav + Footer)
const query = graphql`
  query navQuery {
    layout: allPrismicGlobalLayout {
      edges {
        node {
          lang
          data {
            nav_hero
            nav_intro
            nav_products
            nav_contact
            nav_faq
            nav_policy
            nav_cookies
            footer_legal
          }
        }
      }
    }
  }
`;

const Layout = ({ children, pageContext: { locale } }) => {
  const globalContent = useStaticQuery(query);
  const globalContentData = globalContent.layout.edges.filter(
    node => node.node.lang === locale
  );
  const localizedGlobalContent = globalContentData[0].node.data;

  return (
    <GlobalContext.Provider
      value={{ locale, i18n, globalContent: localizedGlobalContent }}
    >
      <SEO locale={locale} />
      {children}
      <Footer locale={locale} />
    </GlobalContext.Provider>
  );
};

export { GlobalContext, Layout };

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
};
