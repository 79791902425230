import React, { useContext } from "react";
import LocalizedLink from "../LocalizedLink";
import { GlobalContext } from "../Layout";

import "./footer.css";

const Footer = () => {
  const { globalContent } = useContext(GlobalContext);

  return (
    <footer className="footer">
      <div className="container footer__container">
        <span className="footer__legal">
          © {new Date().getFullYear()} {globalContent.footer_legal}
        </span>
        <ul className="footer__list">
          <li className="footer__link-container">
            <LocalizedLink to="/faq" className="footer__link">
              {globalContent.nav_faq}
            </LocalizedLink>
          </li>
          <li className="footer__link-container">
            <LocalizedLink to="/privacy-policy" className="footer__link">
              {globalContent.nav_policy}
            </LocalizedLink>
          </li>
          <li className="footer__link-container">
            <LocalizedLink to="/cookies-policy" className="footer__link">
              {globalContent.nav_cookies}
            </LocalizedLink>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
